import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Row, Col, Button, ListGroup } from 'react-bootstrap'
import posed from 'react-pose'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleDown, faArrowCircleUp } from '@fortawesome/free-solid-svg-icons'

const Aws = ({ location }) => {

  const [openCustomer1, setCustomer1] = useState(false)
  const [openCustomer2, setCustomer2] = useState(false)
  const [openContents1, setContents1] = useState(false)
  const [openContents2, setContents2] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "illust/aws_cloud.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
			},
			awsCloud: file(relativePath: { eq: "illust/aws_cloud.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      awsLogo: file(relativePath: { eq: "aws_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      outline: file(relativePath: { eq: "aws_Badge.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
			customer1: file(relativePath: { eq: "aws_itage_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 150, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
			},
      customer1AwsCase: file(relativePath: { eq: "Itage_AwsCase.png" }) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
			},
			customer2: file(relativePath: { eq: "aws_GlobalCast_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      customer2AwsCase: file(relativePath: { eq: "GlobalCast_AwsCase.png" }) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      cloudIcon: file(relativePath: { eq: "icons/cloud.svg" }) {
        publicURL
      },
      maintenanceIcon: file(relativePath: { eq: "icons/maintenance.svg" }) {
        publicURL
      },
      enginnerIcon: file(relativePath: { eq: "icons/enginner.svg" }) {
        publicURL
      },
      consultingIcon: file(relativePath: { eq: "icons/consulting.svg" }) {
        publicURL
      },
      transitionSupportIcon: file(relativePath: { eq: "icons/transition-support.svg" }) {
        publicURL
      },
      monitoringTroubleshootingIcon: file(relativePath: { eq: "icons/monitoring-troubleshooting.svg" }) {
        publicURL
      }
    }
  `)

  const props = {
    open: {
      opacity: 1,
      height: 'auto',
      transition: {
        ease: 'easeOut',
        duration: 300
      }
    },
    closed: {
      opacity: 0,
      height: 0,
    }
  }

  const Box = posed.div(props)

  const Contents1 = () => (
    <div className="aws-examples">
      <ul className="mb-5">
        <li><h3>最適なサーバ構成がわからない</h3></li>
        <li><h3>手順や運用フローがわからない</h3></li>
        <li><h3>セキュリティを強化したい</h3></li>
        <li><h3>従量課金でコストがどれだけ上がるか心配</h3></li>
        <li><h3>障害発生時の対応に不安がある</h3></li>
        <li><h3>AWSに詳しいエンジニアが社内にいない</h3></li>
      </ul>
      <div className="mb-5">AWSの活用をご検討の際、このような課題をお持ちではないでしょうか？<br className="d-none d-lg-block" />
        グローバルワイズではAWS認定資格を有しており、AWSを熟知しているエンジニアがお客様それぞれのニーズに合わせて最適なソリューションをご提供致します。<br className="d-none d-lg-block" />
        また、導入後も、専任エンジニアによる監視・障害対応など運用サポートも準備しています。</div>
      <div className="part-css">
        <h2 class="mr-2 panel-title ">サービスラインアップ</h2>
        <Row className="text-left mb-4">
          <Col lg={6}>
            <div className="shadow-box aws-contents-1 my-3 aws-icons">
              <p className="h2 font-weight-bold"><img src={data.consultingIcon.publicURL} alt="コンサルティング" />コンサルティング</p>
              経験豊富なエンジニアによる技術支援とコンサルティング。
            </div>
          </Col>
          <Col lg={6}>
            <div className="shadow-box aws-contents-1 my-3 aws-icons">
              <p className="h2 font-weight-bold"><img src={data.cloudIcon.publicURL} alt="クラウド導入支援" />クラウド導入支援</p>
              インフラ構築、インスタンス起動、ミドルウェア
              インストール、FW設定、ロードバランサー作成、
              バックアップ設定、DNS設定など。
            </div>
          </Col>
        </Row>
        <Row className="text-left mb-4">
          <Col lg={6}>
            <div className="shadow-box aws-contents-1 my-3 aws-icons">
              <p className="h2 font-weight-bold"><img src={data.transitionSupportIcon.publicURL} alt="移行支援（既設サーバ→AWSへ移行）" />移行支援（既設サーバ→AWSへ移行）</p>
              お客様の環境の調査、検証、AWS環境の構築、移行作業の実施など。
            </div>
          </Col>
          <Col lg={6}>
            <div className="shadow-box aws-contents-1 my-3 aws-icons">
              <p className="h2 font-weight-bold"><img src={data.monitoringTroubleshootingIcon.publicURL} alt="監視・障害対応" />監視・障害対応</p>
              専任エンジニアによる監視・障害対応などのシステム保守。
            </div>
          </Col>
        </Row>
        <Row className="text-left mb-4">
          <Col lg={6}>
            <div className="shadow-box aws-contents-1 my-3 aws-icons">
              <p className="h2 font-weight-bold"><img src={data.maintenanceIcon.publicURL} alt="運用サポート" />運用サポート</p>
              セキュリティパッチ適用など、システム・インフラ運用のご支援。
            </div>
          </Col>
          <Col lg={6}>
            <div className="shadow-box aws-contents-1 my-3 aws-icons">
              <p className="h2 font-weight-bold"><img src={data.enginnerIcon.publicURL} alt="エンジニア作業" />エンジニア作業</p>
              障害対応、運用サポートなどのエンジニアリング作業を時間契約で必要な時にご提供。
            </div>
          </Col>
        </Row>
      </div>
      <div className="shadow-box my-5">
        <h2>導入実績</h2>
        <ListGroup variant="flush">
          <ListGroup.Item>
            電力業　電力需給最適化システム サーバー構築・システム開発・運用保守
          </ListGroup.Item>
          <ListGroup.Item>
            自動車部品製造業　Webサイト統一管理：バラバラに稼働しているグループ企業のWebサイトの管理を一本化
          </ListGroup.Item>
          <ListGroup.Item>
            自動車部品製造業　生産管理システム　サーバー構築・システム開発・運用保守
          </ListGroup.Item>
          <ListGroup.Item>
            自動車部品製造業　物流システム外販化システム　サーバー構築・システム開発・運用保守
          </ListGroup.Item>
          <ListGroup.Item>
            電気機器製造業　受発注管理システム　サーバー構築・システム開発・運用保守
          </ListGroup.Item>
          <ListGroup.Item>
            アミューズメント業　店舗情報リアルタイム管理システム　サーバー構築・システム開発・運用保守
          </ListGroup.Item>
          <ListGroup.Item>
            情報サービス業　農業向け農作物生育支援アプリ　サーバー構築
          </ListGroup.Item>
          <ListGroup.Item>
            情報サービス業　ヘルスケア業向け脳波測定データ管理システム　データ管理：大規模データの蓄積にAWSを活用
          </ListGroup.Item>
          <ListGroup.Item>
            情報サービス業　会計事務所向け会計業務支援システムクラウド化
          </ListGroup.Item>
          <ListGroup.Item>
            人材サービス業　外国人技能実習生人材マッチングサイト　サーバー構築・Webサイト開発
          </ListGroup.Item>
          <ListGroup.Item>
            外国人向け不動産業　会計システムクラウド化：オンプレミスからAWSへ移行
          </ListGroup.Item>
          <ListGroup.Item>
            外国人向け不動産業　固定資産管理システムクラウド化：オンプレミスからAWSへ移行
          </ListGroup.Item>
          <ListGroup.Item>
            住宅用エネルギー機器販売・施工業　クラウドサーバー保守
          </ListGroup.Item>
        </ListGroup>
      </div>
    </div>
  )

  const Contents1Wrapper = () => (
    <div>
      <Row className="business-category align-items-center">
        <Col>
          <p className="h2 font-weight-bold">AWS導入・移行・サポート</p><br className="d-none d-lg-block" />
          AWSを導入したい全てのお客様をコンサルティング・提案から導入・移行・運用までサポートします。
        </Col>
        <Col lg="auto"
          onClick={() => setContents1(!openContents1)}
          style={{ cursor: 'pointer' }} className="text-right"
        >
          <p>{openContents1 ? '詳細を閉じる' : '詳細を開く'}</p>
          <FontAwesomeIcon
            className="ml-2"
            size="lg"
            icon={openContents1 ? faArrowCircleUp : faArrowCircleDown}
          />
        </Col>
      </Row>
      <Box pose={openContents1 ? 'open' : 'closed'}>
        <Contents1 />
      </Box>
      <br />
      <hr className="dots-hr2 mt-3" />
    </div>
  )

  const Contents2 = () => (
    <div className="aws-examples">
      <p>
        ハードウェアを準備する必要がない、スペックの拡張・収縮が自在に行える、最新のセキュリティが常に適応されるなどクラウドのメリットを活かし、
        従来のオンプレミスによるシステム開発と比較し、「システム導入における初期コストの削減」「システム構築期間の大幅削減」「情報システム部門の負荷軽減」など大きなメリットを得ることができます。
      </p>
      <p class="my-5">
        AWSコンサルティングパートナーとしてのクラウドに関するノウハウと設立以来独立系SIerとして事業活動してきた中で集積した様々なシステム開発の実績・ノウハウを活かして、
        お客様のシステム開発・導入・運用における課題を当社で解決する、ワンストップのソリューションをご提供致します。
      </p>
      <div className="part-css">
        <h2 class="mr-2 panel-title ">クラウドシステム開発の流れ</h2>
        <Row className="text-left mb-4">
          <Col lg={6}>
            <div className="shadow-box aws-contents-2 my-3">
              <p className="h2 font-weight-bold"><span class="aws-contents-flow-index">①</span>コンサルティング・ヒアリング</p>
              解決したい課題の確認や課題に対するソリューションのご提案を行います。
              また、システム開発を進めるにあたり、開発するシステムに対する、お客様のご要望をヒアリングします。
            </div>
          </Col>
          <Col lg={6}>
            <div className="shadow-box aws-contents-2 my-3">
              <p className="h2 font-weight-bold"><span class="aws-contents-flow-index">②</span>要件定義・設計</p>
              開発者の視点でお客様のご要望を整理し、フローやシナリオなど、プロジェクトの具体的な進め方（要件）を決定します。
              定めた要件に従い、アプリケーションとクラウド基盤の設計を行います。<br className="d-none d-lg-block" />
              ※システム開発手法は主にアジャイル開発で行います。
            </div>
          </Col>
        </Row>
        <Row className="text-left mb-4">
          <Col lg={6}>
            <div className="shadow-box aws-contents-2 my-3">
              <p className="h2 font-weight-bold"><span class="aws-contents-flow-index">③</span>アプリケーション開発・クラウド構築</p>
              設計内容をもとにアプリケーション開発とクラウド基盤の構築を行います。それぞれ別々の会社で請負うことが多い中、双方、自社内で開発できるのは当社の特徴です。
              一括で開発することで、システム開発全体の把握が容易になり、開発効率向上することで、お客さまへ品質が高いシステムをご提供できます
            </div>
          </Col>
          <Col lg={6}>
            <div className="shadow-box aws-contents-2 my-3">
              <p className="h2 font-weight-bold"><span class="aws-contents-flow-index">④</span>導入</p>
              開発したクラウドシステムをお客様にお使い頂けるよう、導入作業を行います。
              必要に応じて、システム管理者やシステム利用者の皆様にシステムに関する教育も行います。
            </div>
          </Col>
        </Row>
      </div>
      <div className="shadow-box my-5">
        <h2>導入実績</h2>
        <ListGroup variant="flush">
          <ListGroup.Item>
            電力業　電力需給最適化システム
          </ListGroup.Item>
          <ListGroup.Item>
            自動車樹脂成形部品製造業　電子あんどんシステム
          </ListGroup.Item>
          <ListGroup.Item>
            自動車製造業　生産管理システム
          </ListGroup.Item>
          <ListGroup.Item>
            自動車部品製造業　物流システム外販化システム
          </ListGroup.Item>
          <ListGroup.Item>
            電気機器製造業　受発注管理システム
          </ListGroup.Item>
          <ListGroup.Item>
            不動産業　営業支援システム
          </ListGroup.Item>
          <ListGroup.Item>
            アミューズメント業　店舗情報リアルタイム管理システム
          </ListGroup.Item>
          <ListGroup.Item>
            教育機関　新型コロナウィルス予防ワクチン接種管理システム
          </ListGroup.Item>
          <ListGroup.Item>
            商社　営業案件管理システム
          </ListGroup.Item>
          <ListGroup.Item>
            靴製造業　靴オーダーサイト構築
          </ListGroup.Item>
          <ListGroup.Item>
            医療ガス販売業　販売管理システム
          </ListGroup.Item>
        </ListGroup>
      </div>
    </div>
  )

  const Contents2Wrapper = () => (
    <div>
      <Row className="business-category align-items-center">
        <Col>
          <p className="h2 font-weight-bold">クラウドシステム開発</p><br className="d-none d-lg-block" />
          クラウドシステムを活用することにより、ビジネスをよりスピーディーに、柔軟に推進していただけます。
        </Col>
        <Col lg="auto"
          onClick={() => setContents2(!openContents2)}
          style={{ cursor: 'pointer' }} className="text-right"
        >
          <p>{openContents2 ? '詳細を閉じる' : '詳細を開く'}</p>
          <FontAwesomeIcon
            className="ml-2"
            size="lg"
            icon={openContents2 ? faArrowCircleUp : faArrowCircleDown}
          />
        </Col>
      </Row>
      <Box pose={openContents2 ? 'open' : 'closed'}>
        <Contents2 />
      </Box>
      <br />
      <hr className="dots-hr2 mt-3" />
    </div>
  )

  const Customer1 = () => (
    <div className="aws-examples">
      <p>
        株式会社ＩＴＡＧＥ（愛知県名古屋市）はハウス園芸業者や農業関連機関に向けに、
        ICTを活用した作物の最適な生育環境を構築することを目的として開発した「生育ナビ」を展開しています。
      </p>
      <p>
        生育ナビはスマートホンで対象の農作物を撮影し、生育状況の測定・見える化、画像及びデータをクラウドへ蓄積します。
        これまでの農家の勘所、長年の経験を数値化することで、経験の伝承をより容易に行え、高品質の農作物を継続的に生産することが可能となります。
      </p>
      <p>
        開発当初からシステム基盤にはクラウドを利用する予定でした。システムで処理及び保持するデータ量の予測が困難なので、
        スモールスタートが可能で、基盤のスペックを柔軟に変更することができ、システム運用工数・費用が削減できることからクラウドが生育ナビのシステム基盤に最適と考えました。
      </p>
      <p>
        その上で、複数のクラウドベンダーの提案の中からトータルでコストメリットが最も高かったＡＷＳを利用することに決定しました。
      </p>
      <div className="shadow-box my-5">
        <Img fluid={data.customer1AwsCase.childImageSharp.fluid} className="mx-auto" alt="株式会社ITAGE様_AWSCase" />
      </div>
      <p className="mb-2">
        アプリケーション基盤はECS(Fargate)を採用し、負荷に応じたオートスケールを実現。<br className="d-none d-lg-block" />
        またCodeCommit、CodeBuild、CodePipelineを組み合わせて、開発からデプロイまでの省力化（CI/CD)を実現しています。
      </p>
    </div>
  )

  const Customer1Wrapper = () => (
    <div>
      <Row className="business-category align-items-center">
        <Col lg={3}>
          <br /><Img fluid={data.customer1.childImageSharp.fluid} className="mx-auto" alt="株式会社ITAGE様" />
        </Col>
        <Col lg>
          <p>
            株式会社ITAGE様<br />
            <a href="https://www.itage.co.jp/">https://www.itage.co.jp/</a><br />
            農作物の生育状況見える化サービスにAWSを活用。<br />
          </p>
        </Col>
        <Col lg="auto"
          onClick={() => setCustomer1(!openCustomer1)}
          style={{ cursor: 'pointer' }} className="text-right"
        >
          <p>{openCustomer1 ? '詳細を閉じる' : '詳細を開く'}</p>
          <FontAwesomeIcon
            className="ml-2"
            size="lg"
            icon={openCustomer1 ? faArrowCircleUp : faArrowCircleDown}
          />
        </Col>
      </Row>
      <Box pose={openCustomer1 ? 'open' : 'closed'}>
        <Customer1 />
      </Box>
      <br />
      <hr className="dots-hr2 mt-3" />
    </div>
  )

  const Customer2 = () => (
    <div className="aws-examples">
      <p>
        株式会社グローバルキャスト（愛知県名古屋市）はマーケティング支援事業や、ビジネスサポート事業、ライフコンシェルジュ事業、
        教育事業など多角的に事業展開を行っており、近年積極的に事業拡大を図っています。
      </p>
      <p>
        急速に会社が大きくなっていくなか、主力事業の顧客・取引先の売上・支払管理、受注管理などの基幹業務の属人化、業務工数負荷の増大が課題となっていました。
        その課題を解決するために、これまでExcelで行ってきた業務のシステム化を行いました。
      </p>
      <p>
        売上・支払・受注を一元管理。業務負荷が大きくなっていた代理店への手数料計算と支払通知を自動化することで、担当者の負荷を軽減させ、業務の迅速化を図りました。
        また、事業を拡大していく中で、迅速な経営判断が必要になってくるため、
        システムで管理しているデータから各種帳票や分析資料を作成できるよう、構築しました。
      </p>
      <p>
        システムを構築するにあたり、顧客・取引先の増加量とスピードの予測が困難で、基盤のスペックを決定するのが難しい。とはいっても、
        事業の成長スピードを緩めるわけにもいかないので、なるべく早くシステム化を実現したい。<br className="d-none d-lg-block" />
        この2点の課題を解決するため、基盤にＡＷＳを利用することを検討しました。
      </p>
      <p>
        ＡＷＳであれば、スモールスタートから始めて、事業状況に合わせて、柔軟に、素早く基盤のスペックを変更することができ、
        基盤構築にかかる時間を大きく削減できることから利用を決定、AWSでシステムを稼働させています。
      </p>
      <div className="shadow-box my-5">
        <Img fluid={data.customer2AwsCase.childImageSharp.fluid} className="mx-auto" alt="株式会社グローバルキャスト様_AWSCase" />
      </div>
    </div>
  )

  const Customer2Wrapper = () => (
    <div>
      <Row className="business-category align-items-center">
        <Col lg={3}><br /><Img fluid={data.customer2.childImageSharp.fluid} className="mx-auto" alt="株式会社グローバルキャスト様" /></Col>
        <Col lg>
          <p>株式会社グローバルキャスト様<br />
            <a href="https://global-cast.co.jp/">https://global-cast.co.jp/</a><br />
            基幹業務の効率化・システム化をAWSを活用して実現。
          </p>
        </Col>
        <Col lg="auto"
          onClick={() => setCustomer2(!openCustomer2)}
          style={{ cursor: 'pointer' }} className="text-right"
        >
          <p>{openCustomer2 ? '詳細を閉じる' : '詳細を開く'}</p>
          <FontAwesomeIcon
            className="ml-2"
            size="lg"
            icon={openCustomer2 ? faArrowCircleUp : faArrowCircleDown}
          />
        </Col>
      </Row>
      <Box pose={openCustomer2 ? 'open' : 'closed'}>
        <Customer2 />
      </Box>
      <br />
      <hr className="dots-hr2 mt-3" />
    </div>
  )


  return (
    <Layout location={location} title="AWS" >
      <SEO title="AWS" description="AWS活用することで、ビジネスをよりスピーディーに、柔軟に" />

      <div className="page-head">
        <Container expand="lg">
          <BackgroundImage fluid={data.background.childImageSharp.fluid} id="page-head-image" >
            <h1 className="aws-headline">AWS</h1>
            <h1>
              お客様の<span className="p-blue">クラウドジャーニー</span>の成功を<span className="p-blue">サポート</span><br className="d-none d-lg-block" />
            </h1>
            <p className="mb-2">
              クラウドジャーニーとは、企業におけるクラウド化への継続的な取り組みを旅路に例えた言葉です。<br className="d-none d-lg-block" />
              「クラウド戦略立案」「クラウド移行」「クラウド構築」「クラウド管理」など、クラウド化を進めるために<br className="d-none d-lg-block" />
              必要な各フェーズを、当社のAWS認定資格を有するエンジニアがご支援致します。<br className="d-none d-lg-block" />
            </p>
          </BackgroundImage>
        </Container>
        <div className="bottom-border" />
      </div>

      <Container expand="lg" className="text-left">
        <div className="part-css">
          <h2 className="mr-2">AWSとは</h2>
          <div className="aws-main-part">
            <div className="px-0 mb-4 col-md-9 left_side">
              AWSはAmazonが提供しているクラウドコンピューティングサービスの総称です。
              ストレージ、データベース、分析ツール、アプリケーション、など175を超えるサービスを提供しています。<br className="d-none d-lg-block" />
              グローバルワイズはAWSのコンサルティングパートナーとして2015年から活動、長年SIerとして活動してきたノウハウをもとに、
              AWSを活用したシステム構築、システム運用支援などにより、
              IT投資コスト削減やビジネスのスピードアップなどお客様のITに関わる課題へのソリューションをワンストップでご提供致します。
            </div>
            <div className="col-md-3 right_side">
              <Img fluid={data.outline.childImageSharp.fluid} className="mx-auto" alt="サービス概要" />
            </div>
            <div class="clear"></div>
          </div>
        </div>

        <div className="part-css">
          <h2 className="mr-2">当社の強み</h2>
          <ul className="ml-0 d-inline-block text-left mb-5 ul-custom">
            <li>AWSを中心にマルチクラウド対応可。予算やシステム特性に合わせて自在に構築。</li>
            <li>クラウド構築からシステム開発、システム運用保守までをワンストップで実現。</li>
            <li>AWS認定資格を所有するエンジニアが構築から運用まで全面サポート。クラウドの導入を始めてご検討される方もご安心下さい。</li>
          </ul>
        </div>

        <div>
          <h2 className="mr-2">サービス内容</h2>
          <Contents1Wrapper />
          <Contents2Wrapper />
        </div>

        <div>
          <h2 className="mr-2">導⼊事例</h2>
          <Customer1Wrapper />
          <Customer2Wrapper />
        </div>

        <div className="text-center">
          <Button
            variant="primary"
            className="py-4 px-5"
            href="/awsContact/"
            target="_blank" rel="noopener noreferrer"
          >
            <span className="special-font-size_2">お問合せはこちら</span>
          </Button>
        </div>

      </Container>
    </Layout>
  )
}

export default Aws